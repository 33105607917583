import { cache, createAsync, useNavigate } from "@solidjs/router";
import {
  Accessor,
  createContext,
  createEffect,
  JSX,
  Show,
  useContext,
} from "solid-js";
import { createStore } from "solid-js/store";
import { clientRepo } from "~/server/apis/client_repo";
import {
  getPlutoRouteData,
  PlutoRouteData,
} from "~/server/data/pluto_route_data";
import { ChatDetails, Credits, GiftCardDetails } from "~/server/types/pluto";

type PlutoProviderProps = {
  children: JSX.Element;
};

interface PlutoGifts {
  conversations: ChatDetails[];
  newGiftCardDetails: GiftCardDetails | null;
  credits: Credits;
  userName: string;
  isSidebarOpen: boolean;
}

type PlutoContextValue = {
  plutoGifts: PlutoGifts;
  setNewGiftCard: (giftCardDetails: GiftCardDetails) => void;
  updateGiftCardDetails: (keyPath: string, value: any) => void;
  updateCredits: (credits: Credits) => void;
  clearNewGift: () => void;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  syncCredits: () => Promise<void>;
  syncConversations: () => Promise<void>;
};

const PlutoContext = createContext<PlutoContextValue>();

const getPlutoRouteData$C = cache(getPlutoRouteData, "pluto");

export function PlutoProvider(props: PlutoProviderProps) {
  const routeData: Accessor<PlutoRouteData | undefined> =
    createAsync<PlutoRouteData>(() => getPlutoRouteData$C());

  const [plutoGifts, setPlutoGifts] = createStore<PlutoGifts>({
    conversations: routeData()?.conversations as ChatDetails[],
    credits: routeData()?.credits as Credits,
    newGiftCardDetails: null,
    userName: routeData()?.userName as string,
    isSidebarOpen: false,
  });

  createEffect(() => {
    if (routeData()) {
      setPlutoGifts({
        conversations: routeData()?.conversations as ChatDetails[],
        credits: routeData()?.credits as Credits,
        userName: routeData()?.userName as string,
      });
    }
  });
  const toggleSidebar = () => {
    setPlutoGifts("isSidebarOpen", !plutoGifts.isSidebarOpen);
  };

  const closeSidebar = () => {
    setPlutoGifts("isSidebarOpen", false);
  };

  const setNewGiftCard = (giftCardDetails: GiftCardDetails) => {
    if (giftCardDetails) {
      setPlutoGifts("newGiftCardDetails", giftCardDetails);
    }
  };

  const updateGiftCardDetails = (keyPath: string, value: any) => {
    const keys = keyPath.split(".");
    setPlutoGifts("newGiftCardDetails", (prev) => {
      if (!prev) return prev;

      const updated = { ...prev };
      let current: any = updated;

      for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        if (!current[key]) current[key] = {};
        current = current[key];
      }

      current[keys[keys.length - 1]] = value;
      return updated;
    });
  };

  const updateCredits = (credits: Credits) => {
    if (credits) {
      setPlutoGifts("credits", credits);
    }
  };

  const clearNewGift = () => {
    setPlutoGifts("newGiftCardDetails", null);
  };

  async function syncConversations() {
    const conversations = await clientRepo.getAllConversations();
    setPlutoGifts("conversations", conversations);
  }

  async function syncCredits() {
    const credits = await clientRepo.getPlutoCredits();
    setPlutoGifts("credits", credits);
  }

  return (
    <PlutoContext.Provider
      value={{
        plutoGifts,
        setNewGiftCard,
        updateGiftCardDetails,
        updateCredits,
        clearNewGift,
        toggleSidebar,
        closeSidebar,
        syncConversations,
        syncCredits,
      }}
    >
      <Show when={routeData()}>{props.children}</Show>
    </PlutoContext.Provider>
  );
}

export function usePluto() {
  return useContext(PlutoContext)!;
}
